<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-11-02 19:00:09
-->
<template>
	<div>
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item :to="{path: '/region/regionManage'}">{{ $t('route.region') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{roomName}}</el-breadcrumb-item>
		</el-breadcrumb>
    <div class="contentBox">
      <div class="nodata" v-if="mapList.length===0">
				<img :src="totalImg.nodata" width="62"/>	
				<p>{{$t("region.noMapTip")}}</p>
			</div>
      <div v-for="(item,key) in mapList" class="cardBox" :key="key">
        <div class="oneCard">
          <div class="card-header">
            <span class="mapName" :title="item.map_name">{{item.map_name}}</span>
          </div>
          <div class="card-body"  @click="openMap(item)">
            <el-form title=""  :column="1" label-width="40%" label-position="left">
              <el-form-item :label="$t('region.sourceRobot')">
                <span class="robotsn" :title="item.nickname ? item.nickname : item.robotsn">{{item.nickname ? item.nickname : item.robotsn}}</span>
              </el-form-item>
              <el-form-item :label="$t('region.UpdateTime')">{{moment(item.UpdatedAt).format(momentStamp('dateTime'))}}</el-form-item>
            </el-form>
          </div>
          <div class="card-footer">
            <el-button type="primary" @click="openNewName(item)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{$t("system.rename")}}</el-button>
            <el-button type="primary" @click="delMap(item)" @keyup.space.prevent.native @keydown.enter.prevent.native>{{$t("system.delete")}}</el-button>
          </div>
        </div>
      </div>
      <div class="pageBox">
        <el-pagination
          background
          v-if="pageObject.total/pageObject.pageSize > 1"
          :page-size="pageObject.pageSize"
          :total="pageObject.total"
          :page-sizes="[6, 12, 24, 48, 96, 200]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, prev, pager, next, sizes, jumper"
          class="pagination"
        />
        <div v-else class="noPage">{{$t('system.page').replace('XX', pageObject.total)}}</div>
      </div>
    </div>
    
    <el-dialog
      :title="currentMap.map_name"
      v-model="mapVisible"
      :before-close="closeDialog"
      width="800px">
      <MapDetail :mapInfo="currentMap" ref="mapChildRef" width="760" height="700"></MapDetail>
    </el-dialog>

    <el-dialog
      :title="$t('region.reNewMap')"
      v-model="changeNameVisible"
      width="420px">
      <el-form ref="newNameRef" :model="newNameform" :rules="newNameRules">
        <el-form-item label="" prop="newMapName" >
          <el-input v-model="newNameform.newMapName" :placeholder="$t('region.enterNewMapName')" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="resetNewName" @keyup.space.prevent.native @keydown.enter.prevent.native>{{$t('system.cancel')}}</el-button>
        <el-button type="primary" @click="submitNewName" @keyup.space.prevent.native @keydown.enter.prevent.native>{{$t('system.determine')}}</el-button>
      </template>
    </el-dialog>

	</div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
	toRefs,
	unref,
} from 'vue'
import { ElMessageBox, ElMessage, ElForm  } from 'element-plus';
import { useRouter } from 'vue-router'
import moment from 'moment'
import { totalImg, momentStamp } from '@/utils/robot' 
import { roomMaplist, reNameMap, delRoomMap } from '@/apis/region'
import { stat } from 'fs';
import i18n from '@/locales/index'
import { useRoute } from "vue-router";
import MapDetail from './MapDetail.vue'
let _t = i18n.global.t

export default {
  components: {
    MapDetail,
  },
  setup() {
    const route = useRoute()
    const mapChildRef = ref(null)
    const newNameRef = ref(ElForm);
    const checkName = (rule, value, callback) => {
      if (value===undefined || value === '' || value.trim() === '') {
        callback(new Error(_t("region.enterName")));
      } else {
        callback();
      }
    };
    const state = reactive({
      roomId: route.query.roomid,
      roomName: route.query.Areaname+'-'+route.query.Roomname,
      totalImg: totalImg(),
      momentStamp: momentStamp,
      mapVisible: false,
      changeNameVisible: false,
      currentMapName: '',
      currentMap: {},
      mapList: [],
      pageObject: {
        page: 1,
        pageSize: 6,
        total: 0,
        limit: true
      },
      newNameform: {
        newMapName: '',
      },
      newNameRules: {
        newMapName: [{ validator: checkName, trigger: 'blur' }],
      },
      changeNameVisible: false,
    });
    const methods = reactive({
      getMapList() {
        const params = {
          room: state.roomId,
          ...state.pageObject,
        }
        roomMaplist(params).then(res => {
          if(res.code===200) {
            state.mapList = res.data.list??[]
            state.pageObject.total = res.data.total
          }
        }).catch(error => {})
      },
      delMap(obj) {
        ElMessageBox.alert(_t("region.confirmDelMap"), _t("region.delMap"), {
          confirmButtonText: _t("system.delete"),
          callback: (action) => {
            if(action==='confirm') {
              const params = {
                room: obj.room_id,
                mapids: String(obj.ID)
              }
              delRoomMap(params).then(res => {
                if(res.code === 200) {
                  ElMessageBox.alert(_t('system.deleteSuccess'), _t('system.tips'), {
						  	    confirmButtonText: _t('system.determine'),
                  });
                  methods.getMapList()
                } else if(res.code === 10003) {
                  ElMessage({
						        type: 'error',
						        message: _t('region.delMapNoRoom')
					        })
                }
              }).catch(err => console.log('err', err))
            }
          },
        })
      },
      openNewName(item) {
        state.currentMap = item
        state.newNameform.newMapName = item.map_name
        state.changeNameVisible = true
      },
      submitNewName() {
        unref(newNameRef).validate((valid) => {
          if (valid) {
            const params = {
              room: state.currentMap.room_id,
              body: {
                mapName: state.newNameform.newMapName,
                name: state.currentMap.name
              }
            }
            reNameMap(params).then(res => {
              if(res.code==200){
                ElMessageBox.alert(_t("region.reNameMapSuccess"), _t('system.tips'), {
                  confirmButtonText: _t('system.determine'),
                  callback: action => {
                    methods.getMapList()
                    methods.resetNewName()
                  }
						    });
              } else if(res.code===10001) {
                ElMessageBox.alert(_t("region.mapNameconflict"), _t('system.tips'), {
                  confirmButtonText: _t('system.determine'),
						    });
              }
            }).catch(err => console.log('err'))
          } else {
            return false
          }
        })
      },
      resetNewName() {
        unref(newNameRef).resetFields() 
        state.currentMap = {}
        state.changeNameVisible = false
      },
      openMap(item) {
        if(item.png_map) { // 判断生成地图的基本信息是否有 // 2023 3.2 修改为只要有地图就显示 去除以前的判断有没有地图点位
          state.mapVisible = true
          state.currentMap = item
          setTimeout(()=>{
            mapChildRef.value.openMap()
          }) 
        } else {
          ElMessage({
						type: 'error',
						message: _t('disinfect.gqyzwdt')
					})
        }
      },
      closeDialog(done) {
        mapChildRef.value.closeCanvas()
        done()
      },
      handleCurrentChange(page) {
        // console.log(page)
        state.pageObject.page = page;
        methods.getMapList();
      },
      handleSizeChange(val) {
        state.pageObject.pageSize = val;
        methods.getMapList();
      },

		})
    onMounted(() => {
      methods.getMapList()
    })
    
    return {
      moment,
      mapChildRef,
      newNameRef,
      ...toRefs(state),
      ...toRefs(methods),
    }
  }
}
</script>

<style lang="scss" scoped>
.contentBox{
  margin: 24px;
  background: #fff;
  .nodata{
    text-align: center;
    padding: 200px 0 250px;
  }
}
.cardBox{
  width: 33.3%;
  padding: 20px 0;
  display: inline-block;
  vertical-align: top;
  
  .oneCard{
    width: 90%;
    min-width: 312px;
    margin: 0 auto;
    box-shadow: 2px 4px 5px 0px rgba(0, 21, 41, 0.12), -2px 0px 5px 0px rgba(0, 21, 41, 0.12);
    border-radius: 4px;
    background: #fff;
    position: relative;
    height: 226px;
    .robotsn{
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
  .card-header{
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 20px;
    .mapName{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      word-break: break-all;
    }
  }
  .card-body{
    padding: 5px 30px;
    cursor: pointer;
    .el-form-item {
      margin: 0;
      .el-form-item__label{
        color: #606060 !important;
      }
    }
  }
  .card-footer{
    margin: 20px 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    .el-button{
      width: 50%;
      background: #fafafa;
      color: #838383;
      border: 0.5px solid #f0f0f0;
      line-height: 40px;
      margin: 0;
      border-radius: 0px;
    }
  }
}
.pageBox{
	height: 102px; 
	background: #fff; 
	width: 100%;
	.noPage{
		text-align: right;
    padding: 46px 24px 28px;
    font-size: 14px;
    color: #606266;
	}
}


</style>
<style lang="scss" >
.card-body {
  .el-form-item__label{
    cursor: pointer;
  }
}
</style>
