<!--
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-11-02 19:00:09
-->
<template>
	<div>
      <div id="canvasBox" class="canvasBox">
        <canvas id="map-canvas"></canvas>
        <div class="zoom">
          <div class="add" @click="addScale"></div>
          <div class="reduce" @click="minScale"></div>
          <img src="@/assets/images/map/zoom.png" class="zoomImg" alt="">
        </div>
      </div>
	</div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
	toRefs,
	unref,
} from 'vue'
import { ElMessageBox, ElMessage, ElForm  } from 'element-plus';
import { totalImg } from '@/utils/robot' 
import { stat } from 'fs';
import i18n from '@/locales/index'
import { fabric } from "fabric";
let _t = i18n.global.t

export default {
  props: ['mapName', 'mapInfo', 'width', 'height'],
  setup(props) {
    let canvas = '';
    let mapScale = 1;
    let mapOriginScale = 1;
    let isMouseDown = false;
    let lastPosition = {
      x: '',
      y: ''
    };
    const state = reactive({
      totalImg: totalImg(),
      mapName: props.mapInfo.map_name,
      width: props.width,
      height: props.height,
      mapInfo: props.mapInfo
    });
    const methods = reactive({
      openMap() {
        let detail = JSON.parse(JSON.stringify(props.mapInfo))
        detail.point = detail.point ? JSON.parse(detail.point) : []
        detail.wall = detail.wall ? JSON.parse(detail.wall) : []
        detail.info = JSON.parse(detail.info)
        detail.safe_area = detail.safe_area?JSON.parse(detail.safe_area):[]
        console.log(detail.safe_area,'safe_area')
        state.mapInfo = detail
        methods.setCanvas(state.mapInfo)
      },
      setCanvas(obj) {
        canvas = new fabric.Canvas('map-canvas', {
          backgroundColor: '#DDF0F9',
          width: state.width,
          height: state.height,
          selectable: false
        });
        methods.setBgImg(obj)
      },
      bindEvents() {
        canvas.on({
          'mouse:down': methods.handleOnMouseDown,
          'mouse:move': methods.handleOnMouseMove,
          'mouse:up': methods.handleOnMouseUp,
        });
        canvas.getZoom = function (params) {
          const { scaleX } = fabric.util.qrDecompose(this.viewportTransform);
          return scaleX;
        };
      },
      handleOnMouseDown(opt) {
        let hasMoved = false
        const { absolutePointer } = opt;
        lastPosition = {
          x: absolutePointer.x,
          y: absolutePointer.y
        }
        let mouseFrom = opt.absolutePointer
        isMouseDown = true
      },
      handleOnMouseUp() {
        isMouseDown = false
      },
      handleOnMouseMove(opt) {
        if (!isMouseDown) {
          return
        }
        const { absolutePointer } = opt;
        const { x, y } = lastPosition;
        let px = absolutePointer.x - x;
        let py = absolutePointer.y - y;

        const vpt = canvas.viewportTransform.slice(0);
        vpt[4] += px;
        vpt[5] += py;

        canvas.setViewportTransform(vpt);
        canvas.requestRenderAll();
      },
      setBgImg(obj) {
        const canvasBox = document.getElementById('canvasBox');
        fabric.Image.fromURL('data:image/png;base64,' + obj.png_map, (img) => {
          // 背景图置于画布(0.0)，通过缩放调整画布,使背景图全图在页面中间
          img.set({
            left: 0,
            top: 0
          });
          const clientWidth = state.width
          const clientHeight = state.height
          const { width, height } = img;
          let scaleX = clientWidth / width;
          let scaleY = clientHeight / height;
          let scale = scaleX <= scaleY ? scaleX : scaleY;
          const left = (clientWidth - width) / 2;
          const top = (clientHeight - height) / 2;
          canvas.setViewportTransform([1, 0, 0, 1, left, top]); // [宽缩放倍数, x轴翻转角度, y轴翻转角度, 高缩放倍数, x, y]]
          canvas.zoomToPoint({
            x: clientWidth / 2,
            y: clientHeight / 2
          }, scale);
          
          mapOriginScale = scale
          mapScale = scale
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
          obj.safe_area && obj.safe_area.length>0 ? methods.setsafe_areaLine(obj.info, obj.safe_area) : null
          methods.setPoint(obj.info, obj.point)
          obj.wall && obj.wall.length>0 ? methods.setLine(obj.info, obj.wall) : null
          methods.bindEvents()
        });
      },
      setPoint(info, pointList) {
        pointList.length>0 ? pointList.forEach((point, index) => {
            let rawInfo = {
              "x": point.x,
              "y": point.y,
              "theta": point.theta,
              "orient_w": 0,
              "orient_z": 0
            }
            let pixInfo = methods.makePixInfo(rawInfo, info)
            if(point.action === "nav") {
              methods.addNavPoint(
                pixInfo.x,
                pixInfo.y,
                pixInfo.angle,
                point.idx,
                'nav',
                rawInfo
              )
            } else if (point.action === 'disinfect') {
              methods.addUvPoint(
                pixInfo.x,
                pixInfo.y,
                pixInfo.angle,
                point.idx,
                'disinfect',
                rawInfo
              )
            }

        }) : null
        setTimeout(() => {
           pointList.forEach((point) => {
              if (point.action == "dock" || point.phase_type == "navigation_dock") { //充点电
              let rawInfo = {
                "x": point.x,
                "y": point.y,
                "theta": point.theta,
                "orient_w": 0,
                "orient_z": 0
              }
              let pixInfo = methods.makePixInfo(rawInfo, info)
               methods.addBatteryPoint(
                pixInfo.x,
                pixInfo.y,
                pixInfo.angle,
                point.idx,
                'dock',
                rawInfo
              )
            }
          })
         }, 100);
      },
      setsafe_areaLine(info, safeList){
        safeList.forEach((item,index)=>{
          const newlist = item.data.length>0 ? item.data.map((one, i) => {return methods.safeinfo(one, i, info)}) : []
          console.log(newlist,'newlist')
          let newlistsafe = []
          let safalist = []
          newlist.map((ele,eleindex)=>{
            if (eleindex%2==0) {
              newlistsafe.push({
                x:ele
              })
            } else {
              newlistsafe.push({
                y:ele
              })
            }
          })
         
         newlistsafe.forEach((safeele,index)=>{
            if (index%2==0) {
              safalist.push(Object.assign(safeele,newlistsafe[index+1]))
            }
          })
          console.log(safalist)
         var rect =  new fabric.Polygon(safalist, { 
            fill: 'rgba(60,179,113,0.4)', // 填充色
            stroke: '#fff', // 线段颜色：紫色
            strokeWidth: 0, // 线段粗细 5
            selectable: false
          })
          // rect.set('type', 'wallLine')
          canvas.add(rect);
        })
      },
      setLine(info, wallList) {
        wallList.forEach((item, index) => {
          const newlist = item.length>0 ? item.map((one, i) => {return methods.makeWallInfo(one, i, info)}) : []
          console.log(newlist)
          var rect = new fabric.Line(newlist.slice(0, 4), { 
            stroke: 'rgba(0,0,0,0.8)',
            strokeDashArray: [3, 1],
            strokeWidth: 2,
            selectable: false
          })
          rect.set('type', 'wallLine')
          canvas.add(rect);
        })
      },
      addNavPoint(x, y, angle, index, type, rawInfo) {
        fabric.Image.fromURL(require('@/assets/images/map/nav.png'), (img) => {
          let img1 = img.scale(0.6).set({
            left: 0,
            top: 0,
            originX: 'center',
            originY: 'center'
          });
          let text = new fabric.Text(index + '', {
            fontSize: 16,
            originX: 'center',
            originY: 'center',
            fill: "#8C8C8C",
            fontStyle: 'normal',
            top: -7
          });
          let group = new fabric.Group([img1, text], {
            originX: 'center',
            originY: 'center',
            left: x,
            top: y,
            angle: 0,
            lockMovementX: true,
            lockMovementY: true
          });
          group.set("type", 'nav')
          group.set("idx", index)
          group.set("rawInfo", rawInfo)
          group.set("selectable", false)
          group.scale(1 / mapScale)
          canvas.add(group);
        });
      },
      addUvPoint(x, y, angle, index, type, rawInfo) {
        fabric.Image.fromURL(require('@/assets/images/map/disfinect.png'), (img) => {
          let img1 = img.scale(0.6).set({
            left: 0,
            top: 0,
            originX: 'center',
            originY: 'center'
          });
          let text = new fabric.Text(index + '', {
            fontSize: 16,
            originX: 'center',
            originY: 'center',
            fill: "#05B4BC",
            fontStyle: 'normal',
            top: -7
          });
          let group = new fabric.Group([img1, text], {
            originX: 'center',
            originY: 'center',
            left: x,
            top: y,
            angle: 0,
            lockMovementX: true,
            lockMovementY: true
          });
          group.set("type", 'disinfect')
          group.set("idx", index)
          group.set("rawInfo", rawInfo)
          group.set("selectable", false)
          group.scale(1 / mapScale)
          canvas.add(group);
        });
      },
      addBatteryPoint(x, y, angle, index, type, rawInfo) {
        fabric.Image.fromURL(require('@/assets/images/map/battery.png'), (img) => {
          let img1 = img.scale(0.7).set({
            left: 0,
            top: 0,
            originX: 'center',
            originY: 'center'
          });
          let group = new fabric.Group([img1], {
            originX: 'center',
            originY: 'center',
            left: x,
            top: y,
            angle: 0,
            lockMovementX: true,
            lockMovementY: true
          });
          group.set("type", 'dock')
          group.set("idx", index)
          group.set("rawInfo", rawInfo)
          group.set("selectable", false)
          group.scale(1 / mapScale)
          canvas.add(group);
        });
      },
      //计算每个点位的横像素坐标
      calcX(item, mapInfo) {
        let wid = ((item.x - mapInfo.origin.x) / mapInfo.resolution)
        return wid
      },
      //计算每个点位的纵像素坐标
      calcY(item, mapInfo) {
        let hei = mapInfo.height - 1 - ((item.y - mapInfo.origin.y) / mapInfo.resolution)
        return hei
      },
      makePixInfo(rawPos, mapInfo) {
        return {
          x: methods.calcX(rawPos, mapInfo),
          y: methods.calcY(rawPos, mapInfo),
          angle: 0
        }
      },
      makeWallInfo(point, i, mapInfo) {
        const isX = i%2==0 // 判断是x还是y
        return isX ? (point*mapInfo.resolution - mapInfo.origin.x) / mapInfo.resolution  : 
               mapInfo.height - 1 - (point*mapInfo.resolution - mapInfo.origin.y) / mapInfo.resolution
      },
     safeinfo(point,i,mapInfo) {
       const isX = i%2==0 // 判断是x还是y
        return isX ? (point - mapInfo.origin.x) / mapInfo.resolution  : 
        -((point - mapInfo.origin.y) / mapInfo.resolution - mapInfo.height)
     },
      // 缩放
      addScale() {
        methods.scale_canvas(-100, state.width / 2, state.height / 2)
      },
      minScale() {
        methods.scale_canvas(100, state.width / 2, state.height / 2)
      },
      scale_canvas(deltaY, offsetX, offsetY) {
        let zoom = canvas.getZoom();
        zoom *= 0.999 ** deltaY; // 两个*表示幂运算
        if (zoom > 5)
          zoom = 5;
        if (zoom < mapOriginScale)
          zoom = mapOriginScale;
        canvas.zoomToPoint({
          x: offsetX,
          y: offsetY
        }, zoom);
        mapScale = zoom
        methods.updatePointsPostion()
      },
      //因为地图大小实时变化，点位的坐标也要更新
      updatePointsPostion() {
        canvas.forEachObject((obj, i) => {
          if (obj && obj.type && (obj.type === 'disinfect' || obj.type === 'dock' || obj.type===
              'nav')) {
            // let rawInfo = obj.rawInfo
            // let mapPos = {
            //   "x": rawInfo.x,
            //   "y": rawInfo.y,
            //   "theta": rawInfo.theta
            // }
            // let pixPos = methods.makePixInfo(mapPos, state.mapInfo.info)
            // obj.top = pixPos.y
            // obj.left = pixPos.x
            // obj.angle = pixPos.angle
            obj.scale(1 / mapScale)
            // obj.set("selectable", false)
          }
          // if (obj && obj.type && (obj.type === 'wallPoint' || obj.type === 'temp')) {
          //   obj.scale(1 / mapScale)
          // }
          if (obj && obj.type && obj.type === 'wallLine') {
            obj.set("strokeWidth", 1 / mapScale)
          }
        });
      },

      closeCanvas() {
        if(canvas) {
          canvas.clear()
          canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
          canvas.dispose() // 清除初始化创建的canvas等element
        }
      },
      handleCurrentChange(page) {
        state.pageObject.page = page;
        state.getList();
      },
      handleSizeChange(val) {
        state.pageObject.pageSize = val;
        state.getList();
      },

		})
    onMounted(() => {
      // methods.openMap()
    })
    
    return {
      ...toRefs(state),
      ...toRefs(methods),
    }
  }
}
</script>

<style lang="scss" scoped>
.canvasBox{
  margin-right: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  .zoom{
    position: absolute;
    left: 10px;
    bottom: 5px;
    width: 106px;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 300;
    cursor: pointer;
    .reduce,
    .add {
      width: 50%;
      z-index: 301;
    }
    .zoomImg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 106px;
      height: 54px;
    }
  }

}


</style>
